<template>
	<div class="table-wrapper table-selectable">
		<template v-if="selectedConversionGroup === null">
			<!-- TABLE HEADER -->
			<div class="table-header">
				<div class="col-lg-9 col-md-12 table-column table-header-title header-border">
					<i class="button--back" @click="back()" v-if="hasParent">
						<fa icon="chevron-left"/>
					</i>
					<h6 class="conversion-group-title"
						:class="{ 'last-title': !hasParent }">
						{{ $t('conversions.channels') }}
						<span v-if="titles.length > 0" style="margin-right:3px;">/</span>
					</h6>
					<h6 class="conversion-group-title"
						v-for="(title, index) in titles"
						:class="{ 'last-title': index === titles.length-1 }">
						{{ title }}
						<span v-if="titles.length > 0 && index !== titles.length-1" style="margin-right:3px;">/</span>
					</h6>
				</div>

				<div class="col-lg-3 hidden-md hidden-sm hidden-xs">
					<div class="row">
						<div v-for="metric in tableHeaderMetrics"
							 class="table-column insight-explainer-icon"
							 :class="columnWidth">
							<i class="fa" :class="metric.icon"/>
							<small class="table-header__text">{{ $t(`conversions.${metric.metric}`) }}</small>
						</div>
					</div>
				</div>
			</div>

			<!-- TABLE BODY -->
			<!-- Conversions -->
			<div class="table-body">
				<div class="col-lg-12 table-row enabled"
					 v-for="conversionSource in conversions">

					<div class="row">
						<div class="col-lg-9 hidden-md hidden-sm hidden-xs table-column campaign-name header-border">
							{{ $t(`trafficSources.${conversionSource.source}`) }}
						</div>

						<!-- DESKTOP -->
						<div class="col-lg-3 hidden-md hidden-sm hidden-xs">
							<div class="row">
								<div class="table-column insight-value text-center"
									 v-for="metric in tableHeaderMetrics"
									 :class="columnWidth">

									<template v-if="metric.metric === 'conValue'">
										{{ conversionSource.conValue }}
									</template>

									<template v-if="metric.metric === 'amount'">
										{{ conversionSource.value | numberFormat }}
									</template>

									<template v-if="metric.metric === 'percentage'">
										{{ percentage(conversionSource.value) | fixedNumber(0) }}%
									</template>
								</div>
							</div>
						</div>

						<!-- MOBILE -->
						<div class="col-xs-12 col-sm-12 col-md-12 hidden-lg">
							<div class="row mobile-container">
								<!-- TABLE BODY -->
								<template v-for="metric in tableHeaderMetrics">
									<div class="col-md-4 col-sm-4 col-xs-2 table-column insight-explainer-icon">
										<i class="fa" :class="metric.icon"/>
										<small class="visible-on-tablet" v-if="metric.metric === 'conValue'">
											{{ $t('conversions.conValue') }}
										</small>
										<small class="visible-on-tablet" v-if="metric.metric === 'amount'">
											{{ $t('conversions.amount') }}
										</small>
										<small class="visible-on-tablet" v-if="metric.metric === 'percentage'">
											{{ $t('conversions.percentage') }}
										</small>
									</div>

									<div class="col-md-8 col-sm-8 col-xs-10 table-column insight-value text-center">
										<div class="row">
											<div class="col-md-12 col-sm-12 col-xs-12">
												<small class="device-insight--value">
													<template v-if="metric.metric === 'conValue'">
														{{ conversionSource.conValue }}
													</template>

													<template v-if="metric.metric === 'amount'">
														{{ conversionSource.value | numberFormat }}
													</template>

													<template v-if="metric.metric === 'percentage'">
														{{ percentage(conversionSource.value) | fixedNumber(0) }}%
													</template>
												</small>
											</div>
										</div>
									</div>
								</template>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- TABLE BODY -->
			<!-- ConversionGroup -->
			<div class="table-body">
				<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 table-row enabled"
					 v-for="(conversionGroup, index) in conversionGroups"
					 @click="selectConversionGroup(conversionGroup)"
					 v-if="hasLoaded">

					<div class="row">
						<!-- DESKTOP -->
						<div class="col-lg-9 hidden-md hidden-sm hidden-xs table-column campaign-name header-border ">
							<strong>
								{{ conversionGroup.title }}
							</strong>
						</div>

						<div class="col-lg-3 hidden-md hidden-sm hidden-xs">
							<div class="row">
								<div class="table-column insight-value text-center"
									 v-for="metric in tableHeaderMetrics"
									 :class="columnWidth">

									<template v-if="metric.metric === 'conValue'">
										{{ getConversionValues(conversionGroup, metric.metric) }}
									</template>

									<template v-if="metric.metric === 'amount'">
										{{
											missingRequiredIntegration(conversionGroup) &&
											sumConversionsForGroup(conversionGroup, metric.metric) === 0 ?
												'-' :
												sumConversionsForGroup(conversionGroup, metric.metric) | numberFormat
										}}
									</template>

									<template v-if="metric.metric === 'percentage'">
										{{
											percentage(sumConversionsForGroup(conversionGroup, metric.metric)) | fixedNumber(0)
										}}%
									</template>
								</div>
							</div>
						</div>

						<!-- MOBILE -->
						<div class="col-md-12 col-sm-12 col-xs-12 hidden-lg" style="height: 50px; padding-top: 15px;">
							<strong style="margin-bottom: 10px; display: inline-block;">
								{{ conversionGroup.title }}
							</strong><br>
						</div>

						<template v-for="metric in tableHeaderMetrics">
							<div class="col-md-4 col-sm-4 col-xs-2 hidden-lg table-column insight-explainer-icon">
								<i class="fa" :class="metric.icon"/>
								<small class="visible-on-tablet"
									   v-if="metric.metric === 'conValue'">{{ $t('conversions.conValue') }}</small>
								<small class="visible-on-tablet"
									   v-if="metric.metric === 'amount'">{{ $t('conversions.amount') }}</small>
								<small class="visible-on-tablet"
									   v-if="metric.metric === 'percentage'">{{ $t('conversions.percentage') }}</small>
							</div>

							<div class="col-md-8 col-sm-8 col-xs-10 hidden-lg table-column insight-value text-center">
								<div class="row">
									<small class="device-insight--value">
										<template v-if="metric.metric === 'conValue'">
											{{ getConversionValues(conversionGroup, metric.metric)}}
										</template>

										<template v-if="metric.metric === 'amount'">
											{{ sumConversionsForGroup(conversionGroup, metric.metric) | numberFormat }}
										</template>

										<template v-if="metric.metric === 'percentage'">
											{{
												percentage(sumConversionsForGroup(conversionGroup, metric.metric)) | fixedNumber(0)
											}}%
										</template>
									</small>
								</div>
							</div>
						</template>
					</div>
				</div>

				<div class="col-lg-12" style="height: 50px; padding: 13px 16px;" v-else>
					<p class="mock">Analytics goal</p>
				</div>
			</div>
		</template>

		<conversion-types :entries="selectedGroupConversions"
						  @previous="selectedConversionGroup = null"
						  :hasParent="true"
						  :visible="visible"
						  :titles="componentTitles"
						  :hasLoaded="hasLoaded"
						  v-else/>
	</div>
</template>

<style lang="scss" scoped>
@import '~@/assets/scss/_table.scss';
</style>


<script>
import ConversionTypes from '@/app/conversions/components/table/ConversionTypes'

import hasIntegration from '@/mixins/integrations/hasIntegration'
import datepickerMixin from '@/app/datepicker/datepicker.mixin'

export default {
	mixins: [hasIntegration, datepickerMixin],

	name: 'conversion-types',
	props: {
		hasLoaded: {
			type: Boolean,
			required: true
		},
		visible: {
			type: Array,
			required: true
		},
		titles: {
			type: Array,
			required: true
		},
		hasParent: {
			type: Boolean,
			required: false
		},
		entries: {
			type: Array,
			required: true,
			default: () => {
				return []
			}
		}
	},

	data() {
		return {
			selectedConversionGroup: null,
			componentTitles: [],
			tableHeaderMetrics: [
				{
					metric: 'conValue',
					format: 'number',
					icon: 'fa-money'
				},
				{
					metric: 'amount',
					format: 'number',
					icon: 'fa-group'
				},
				{
					metric: 'percentage',
					format: 'number',
					icon: 'fa-percent'
				}
			]
		}
	},

	computed: {
		selectedGroupConversions() {
			const selectedGroup = this.conversionGroups.filter(group => {
				return group.slug === this.selectedConversionGroup
			})[0]

			if (selectedGroup === undefined) {
				return []
			}

			return selectedGroup.conversions
		},

		totalConversions() {
			let totalValue = this.entries.reduce((accumulator, conversion) => {
				if (conversion.type === 'conversionGroup') {
					return accumulator + this.sumConversionsForGroup(conversion, null)
				}

				return accumulator + conversion.value
			}, 0)

			return totalValue !== 0 ? totalValue : null
		},

		conversions() {
			const entries = this.entries
				.filter(entry => entry.type === 'conversion' && this.visible.includes(entry.sourceMedium))
				.reduce((conversionSources, conversion) => {
					const sourceMedium = conversion.sourceMedium;
					let conversionSource = conversionSources[sourceMedium];

					if (conversionSource === undefined) {
						conversionSource = {
							source: sourceMedium,
							value: 0,
							conValue: 0
						}
					}

					conversionSource.value += conversion.value;
					conversionSource.conValue = conversionSource.value * conversion.conValue;

					if (isNaN(conversionSource.conValue) || conversionSource.conValue === 0) {
						conversionSource.conValue = '-';
					}

					conversionSources[sourceMedium] = conversionSource;
					return conversionSources;
				}, {})

			return Object.values(entries).sort((a, b) => b.value - a.value)
		},

		conversionGroups() {
			return this.entries
				.filter(entry => entry.type === 'conversionGroup')
				.map(conversionGroup => {
					const clonedConversionGroup = JSON.parse(JSON.stringify(conversionGroup))
					clonedConversionGroup.total = this.sumConversionsForGroup(conversionGroup, null)

					return clonedConversionGroup
				})
				.sort((a, b) => b.total - a.total)
		},

		columnWidth() {
			const amountOfMetrics = this.tableHeaderMetrics.length

			switch (amountOfMetrics) {
				case 1:
					return 'col-lg-12'
				case 2:
					return 'col-lg-6'
				case 3:
					return 'col-lg-4'
				case 4:
					return 'col-lg-3'
			}
		}
	},

	mounted() {
		this.componentTitles = this.titles
	},

	methods: {
		load() {
			this.selectedConversionGroup = null
			this.componentTitles = []
		},

		percentage(sum) {
			if (!this.totalConversions) {
				return 0
			}

			return (sum / this.totalConversions) * 100
		},

		selectConversionGroup(conversionGroup) {
			if (conversionGroup.slug === 'calls') {
				eventHub.$emit('togglePunchCardGraph', true)
			}

			this.componentTitles.push(conversionGroup.title)
			this.selectedConversionGroup = conversionGroup.slug
		},

		back() {
			eventHub.$emit('togglePunchCardGraph', false)
			this.componentTitles.pop()
			this.$emit('previous')
		},

		getConversionValues(conversionGroup, metric) {
			let sumOfConversion = this.sumConversionsForGroup(conversionGroup, metric);

			if (isNaN(sumOfConversion) || sumOfConversion === 0) {
				sumOfConversion = '-';
			}

			return sumOfConversion;
		},

		missingRequiredIntegration(conversionGroup) {
			return conversionGroup.slug === 'calls' && !this.hasIntegration('Freespee') ||
				conversionGroup.slug === 'emails' && !this.hasIntegration('Formstack') ||
				conversionGroup.slug === 'goals' && conversionGroup.conversions.length === 0
		},

		sumConversionsForGroup(conversionGroup, metric) {
			return conversionGroup.conversions.reduce((accumulator, conversion) => {
				if (conversion.type === 'conversionGroup') {

					return accumulator + this.sumConversionsForGroup(conversion, metric)
				}

				if (conversion.value !== 0 && metric === 'conValue') {
					return accumulator + (conversion.conValue * conversion.value);
				} else {
					return accumulator + conversion.value;
				}
			}, 0)
		}
	},

	components: {
		ConversionTypes
	}
}
</script>
