import { mapGetters } from 'vuex'

export default {
  computed: mapGetters('trafficSources', {
    sources: 'getSources'
  }),

  methods: {
    translate (name, keyPath = 'trafficSources') {
      if (name === 'total') {
        return $t('total')
      }

      const key = `${keyPath}.${name}`
      const translation = $t(key)

      if (key === translation) {
        const source = this.sources.find(source => {
          return source.slug === name
        })

        if (source && source.title) {
          return source.title
        }

        return name
      }

      return translation
    },

    getMedium (medium) {
      let sourceMedium = 'organic'

      this.sources.forEach(source => {
        if (Array.isArray(source.matchingPatterns)) {
          source.matchingPatterns.forEach(matchingPattern => {
            if (medium.includes(matchingPattern)) {
              sourceMedium = source.slug
            }
          })
        }
      })

      return sourceMedium
    },

    getColor (string){
      switch (string) {
        case 'adwords':
          return '#7cb5ec'
        case 'direct':
          return '#434348'
        case 'facebook':
          return '#00C69E'
        case 'organic':
          return '#FE9D45'
        case 'referral':
          return '#8085e9'
        case 'total':
          return '#f15c80'
        default:
          return '#91e8e1'
      }
    },

    colorForMedium (medium) {
      let sources = this.sources

      for (let i = 0; i < sources.length; i++) {
        const organizationSourceKey = `colors.trafficSources.${medium}`

        if (organizationSourceKey !== $org(organizationSourceKey)) {
          return $org(organizationSourceKey)
        }

        if (sources[i].slug !== medium) {
          continue
        }

        return '#' + sources[i].color
      }

      this.getColor(medium)
    }
  }
}
