<template>
	<div class="conversions">
		<check-for-missing-integrations :integrations="['Google analytics']">
			<div slot="no-integrations">
				<landing-page
					image="/images/integrations/cta-messages/conversions.png"
					:headline="$t('conversions.missingIntegration.googleAnalyticsLandingPage.headline')"
					:description="$t('conversions.missingIntegration.googleAnalyticsLandingPage.description')">
					<hr />

					<integration
						service="analytics"
						:border="true"
						logo="/images/onboarding/google-analytics.svg"
						:headline="$t('visitors.onboarding.analytics.headline')"
						:description="$t('visitors.onboarding.analytics.description')"
					/>
				</landing-page>
			</div>

			<div class="container-fluid">
				<div class="col-custom-row">
					<div class="col-custom-4-12 between-md-lg">
						<card :padding="false" style="padding: 0;">
							<div class="row" style="margin: 0;">
								<div
									class="col-lg-12 account-performance__metric--highlight"
									:style="organizationBackgroundColor">
									<div class="row">
										<div class="col-lg-12 account-performance__container">
											<template v-if="hasLoaded">
												<span
													class="account-performance__metric--value"
												>{{
														missingAllConversionGroups ? '-' : totalConversions | numberFormat
													}}</span>
												<span class="account-performance__metric--text"
													  v-text="$t('conversions.conversions')"/>
											</template>

											<template v-else>
												<span class="account-performance__metric--value mock"
													  :style="organizationColor">12.500</span>
												<span class="account-performance__metric--text mock"
													  :style="organizationColor">Spend</span>
											</template>
										</div>
									</div>
								</div>
							</div>
						</card>
					</div>

					<div class="col-custom-8-12 between-md-lg">
						<card :padding="false" style="padding: 0;">
							<div class="row" style="margin: 0;">
								<div
									class="account-performance__metric--normal"
									v-for="(n, key) in filteredConversions"
									:class="dynamicColumnWidth"
									:key="key">
									<span class="account-performance__metric--value"
										  v-if="filteredConversions[key].hasLoaded">
										{{
											missingRequiredIntegration(filteredConversions[key]) && sumConversionsForGroup(filteredConversions[key]) === 0 ?
												'-' : sumConversionsForGroup(filteredConversions[key]) | numberFormat
										}}
									</span>

									<span class="account-performance__metric--value mock" v-else>123</span>
									<span
										class="account-performance__metric--text">{{ $t(`conversions.${key}`) }}</span>
								</div>
							</div>
						</card>
					</div>
				</div>

				<div class="col-custom-row">
					<div class="col-custom-12">
						<card>
							<keep-alive>
								<conversion-chart
									:conversions="filteredGraphConversions"
									:visible="visible"
									v-if="hasLoaded && !punchCardIsActive"
								/>
								<punch-card :from="from" :to="to" :visible="visible"
											v-if="hasLoaded && punchCardIsActive"/>
							</keep-alive>

							<logo-loader :height="250" v-if="!hasLoaded"/>
						</card>
					</div>
				</div>

				<div class="col-custom-row conversion-types">
					<div class="col-custom-12">
						<card
							icon="newspaper"
							:headline="$t('conversions.channels')"
							:description="$t('conversions.getOverview')">
							<conversion-types
								:titles="[]"
								:entries="Object.values(filteredConversions)"
								:hasLoaded="hasLoaded"
								:visible="visible"
							/>
						</card>
					</div>
				</div>

				<div class="col-custom-row ecommerce">
					<div class="col-custom-12">
						<ecommerce />
					</div>
				</div>

				<div class="col-custom-row gmb">
					<div class="col-custom-12">
						<gbp />
						<!--Add back when fixed the request/data to new API in Gmb.vue-->
					</div>
				</div>
			</div>
		</check-for-missing-integrations>
	</div>
</template>

<style lang="scss" scoped>
@import "~@/assets/scss/_vars.scss";
@import "~@/assets/scss/_mixins.scss";

.account-performance__metric--highlight {
	color: #ffffff;
	background: #5192ce;
	text-align: center;
	margin: 0;
	border-radius: 4px;

	.account-performance__container {
		@include ellipsis;
		padding: 27px 20px;

		&:not(:last-child) {
			border-right: 1px solid #4684b2;
		}

		.account-performance__metric--value {
			font-weight: 700;
			font-size: 26px;
			margin: 0;
		}

		.account-performance__metric--text {
			display: block;
		}
	}
}

.account-performance__metric--normal {
	min-height: 111px;
	padding: 25px 30px 0 30px;
	text-align: center;
	position: relative;

	&:not(:last-child) {
		border-right: 1px solid #eaeaea;
	}

	.account-performance__metric--value {
		color: #000000;
		font-size: 26px;
		font-weight: 500;
	}

	.account-performance__metric--text {
		display: block;
	}

	.account-performance__tooltip {
		padding-top: 7px;
		font-size: 12px;
		border-top: 1px solid #eaeaea;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 30%;
		display: inline-block;
		background: #f9f9f9;
	}
}

@media screen and (max-width: 1500px) {
	.between-md-lg {
		flex: 0 0 100%;
	}
}

@media screen and (max-width: 1200px) {
	.account-performance__metric--highlight {
		.account-performance__container {
			padding: 12px;

			&:not(:last-child) {
				border: none;
				border-bottom: 1px solid #4684b2;
			}
		}
	}
	.account-performance__metric--normal {
		width: 100%;

		&:not(:last-child) {
			border: none;
			border-bottom: 1px solid #eaeaea;
		}
	}
}
</style>

<script>
import ConversionChart from "@/app/conversions/components/table/ConversionChart";
import ConversionTypes from "@/app/conversions/components/table/ConversionTypes";
import PunchCard from "@/app/inquiries/components/PunchCard";
import Ecommerce from "@/app/conversions/components/ecommerce/Ecommerce";
import Gbp from "@/app/conversions/components/google-business-profile/Gbp";
import CheckForMissingIntegrations from "@/app/integrations/components/CheckForMissingIntegrations";
import LandingPage from "@/app/onboarding/components/LandingPage";
import Integration from "@/app/onboarding/components/integrations/Integration";

import {mapGetters} from "vuex";

import organization from "@/mixins/customer/organization";
import hasIntegration from "@/mixins/integrations/hasIntegration";
import GoalService from "@/services/_app/google-analytics/GoalService";
import * as CallService from "@/services/inquiries/CallService";
import * as FormService from "@/services/inquiries/FormService";
import trafficSourcesMixin from "@/app/traffic-sources/traffic-sources.mixin";
import datepickerMixin from "@/app/datepicker/datepicker.mixin";

import moment from "moment";

export default {
	mixins: [
		organization,
		hasIntegration,
		datepickerMixin,
		trafficSourcesMixin,
	],

	data() {
		return {
			profile: null,
			conversions: {},
			punchCardIsActive: false,
			visible: [],
			requiredConversionGroups: 3,
		};
	},

	computed: {
		...mapGetters("customer", {
			customer: "getCustomer",
		}),

		dynamicColumnWidth() {
			const columns = Object.values(this.filteredConversions).length;
			return `col-lg-${12 / columns}`;
		},

		hasLoaded() {
			const conversions = Object.values(this.conversions);

			if (conversions.length !== this.requiredConversionGroups) {
				return false;
			}

			for (let i = 0; i < conversions.length; i++) {
				if (!conversions[i].hasLoaded) {
					return false;
				}
			}

			return true;
		},

		organizationColor() {
			return {
				color: `${$org(
					"colors.standard.card.backgroundMock"
				)} !important`,
			};
		},

		organizationBackgroundColor() {
			return {backgroundColor: $org("colors.standard.card.background")};
		},

		missingAllConversionGroups() {
			let missingAllIntegrations = 0;

			Object.values(this.filteredConversions).forEach((group) => {
				if (this.missingRequiredIntegration(group)) {
					missingAllIntegrations++;
				}
			});

			return missingAllIntegrations === this.requiredConversionGroups;
		},

		totalConversions() {
			return Object.values(this.filteredConversions).reduce(
				(accumulator, group) => {
					return accumulator + this.sumConversionsForGroup(group);
				},
				0
			);
		},

		filteredConversions() {
			const clonedConversions = JSON.parse(
				JSON.stringify(this.conversions)
			);

			Object.keys(clonedConversions).forEach((conversionSource) => {
				if (
					conversionSource === "calls" &&
					!this.hasIntegration("Freespee")
				) {
					this.$delete(clonedConversions, "calls");
					return;
				}

				if (
					conversionSource === "emails" &&
					!this.hasIntegration("Formstack")
				) {
					this.$delete(clonedConversions, "emails");
					return;
				}

				clonedConversions[conversionSource] = this.filterConversions(
					clonedConversions[conversionSource]
				);
			});

			return clonedConversions;
		},

		filteredGraphConversions() {
			const clonedConversions = JSON.parse(
				JSON.stringify(this.conversions)
			);

			Object.keys(clonedConversions).forEach((conversionSource) => {
				clonedConversions[conversionSource] =
					this.filterGraphConversions(
						clonedConversions[conversionSource]
					);
			});

			return clonedConversions;
		},
	},

	watch: {
		from() {
			this.loadGoals();
			this.loadInquiries();
		},
	},

	mounted() {
		if (!this.hasHealthyIntegration("Google analytics")) {
			return;
		}

		this.sources.forEach((source) => {
			if (this.getRouteQuery(source.slug)) {
				this.visible.push(source.slug);
			}
		});

		eventHub.$on("togglePunchCardGraph", this.togglePunchCardGraph);


		this.initiateFilter();
		this.loadGoals();
		this.loadInquiries();
	},

	destroyed() {
		eventHub.$off("topbar.filterGroups.sources.metricsChanged");
		eventHub.$off("togglePunchCardGraph", this.togglePunchCardGraph);
	},

	methods: {
		togglePunchCardGraph(isActive) {
			this.punchCardIsActive = isActive;
		},

		filterConversions(conversions) {
			const clonedConversions = JSON.parse(JSON.stringify(conversions));
			const filteredConversions = [];

			clonedConversions.conversions.forEach((conversion) => {
				if (conversion.type === "conversion") {
					if (this.visible.includes(conversion.sourceMedium)) {
						filteredConversions.push(conversion);
					}

					return;
				}

				if (conversion.type === "conversionGroup") {
					filteredConversions.push(
						this.filterConversions(conversion)
					);

					return;
				}
			});

			clonedConversions.conversions = filteredConversions;

			return clonedConversions;
		},

		filterGraphConversions(conversions) {
			const clonedConversions = JSON.parse(JSON.stringify(conversions));
			const filteredConversions = [];

			const conversionsToLoop = clonedConversions.graphConversions
				? clonedConversions.graphConversions
				: clonedConversions.conversions;

			conversionsToLoop.forEach((conversion) => {
				if (conversion.type === "conversion") {
					if (this.visible.includes(conversion.sourceMedium)) {
						filteredConversions.push(conversion);
					}

					return;
				}

				if (conversion.type === "conversionGroup") {
					filteredConversions.push(
						this.filterGraphConversions(conversion)
					);

					return;
				}
			});

			clonedConversions.conversions = filteredConversions;

			return clonedConversions;
		},

		async loadGoals() {
			const output = {
				title: $t("conversions.goals"),
				type: "conversionGroup",
				serieColor: $org("colors.standard.primary.hex"),
				slug: "goals",
				hasLoaded: false,
				conversions: [],
			}

			this.$set(this.conversions, "goals", JSON.parse(JSON.stringify(output)))

			const data = await GoalService.findAll(this.from, this.to);
			const goals = [];

			for (const [goalIndex, goalWithoutInsights] of data.entries()) {
				const conversions = []

				const id = !! goalWithoutInsights.id
					? goalWithoutInsights.id
					: goalWithoutInsights.eventName

				const data = await GoalService.insightsGroupedByDate(
					id,
					this.from,
					this.to,
					this.interval
				)

				if (! data.rows) {
					continue
				}

				const conValue = !! goalWithoutInsights.value
					? goalWithoutInsights.value
					: Math.floor(goalWithoutInsights.eventValue / goalWithoutInsights.conversions)

				Object.values(data.rows).forEach((group) => {
					if (!Array.isArray(group)) {
						group = [group]
					}

					group.forEach((conversion) => {
						const date = {date: moment(`${conversion.date}`).format("YYYY-MM-DD")}
						const sourceMedium = this.getMedium(conversion.sourceMedium)

						let value = 0

						if (conversion.eventCount > 0) {
							value = conversion.eventCount
						}

						if (conversion.conversions > 0) {
							value = conversion.conversions
						}

						if (conversion['conversions:' + id] > 0) {
							value = conversion['conversions:' + id]
						}

						conversions.push({
							type: "conversion",
							sourceMedium,
							value,
							conValue,
							date,
						});
					});
				});

				const goal = {
					title: goalWithoutInsights.name ?? goalWithoutInsights.eventName,
					type: "conversionGroup",
					slug: `goal-${goalIndex}`,
					conversions: conversions,
					graphConversions: conversions,
				};

				goals.push(goal);
			}

			output.conversions = goals
			output.hasLoaded = true

			this.$set(this.conversions, 'goals', output)
		},

		loadInquiries() {
			const sources = {
				calls: {
					color: $org("colors.standard.secondary.hex"),
					slug: "calls",
					service: CallService,
				},

				mails: {
					color: $org("colors.standard.tertiary.hex"),
					slug: "emails",
					service: FormService,
				},
			};

			const from = moment(this.from).seconds(0).minutes(0).hours(0);

			const to = moment(this.to).seconds(23).minutes(59).hours(59);

			const promises = [];

			const inquiries = {
				calls: {},
				emails: {},
			};

			const graphInquiries = {
				calls: {},
				emails: {},
			};

			Object.values(sources).forEach((inquirySource) => {
				this.$set(this.conversions, inquirySource.slug, {
					title: $t(`conversions.${inquirySource.slug}TableHeader`),
					type: "conversionGroup",
					serieColor: inquirySource.color,
					slug: inquirySource.slug,
					hasLoaded: false,
					conversions: [],
				});

				for (
					let start = moment(from);
					start.isBefore(to);
					start.add(1, this.interval)
				) {
					const end = moment(start);
					end.add(1, this.interval);

					const periodExceedsTo = end > this.to;

					if (periodExceedsTo) {
						const endLte = moment(this.to).add(1, "day");

						promises.push(
							new Promise((resolve, reject) => {
								inquirySource.service.countBySources(
									{
										filter_groups: [
											{
												filters: [
													{
														key: "date",
														value: start.format("YYYY-MM-DD"),
														operator: "gt",
													},
													{
														key: "date",
														value: endLte.format("YYYY-MM-DD"),
														operator: "lte",
													},
													{
														key: "customer",
														operator: "eq",
														value: this.customer.id,
													},
												],
											},
										],
									},
									({data}) => {
										if (!data.date) {
											return resolve();
										}

										const date = moment(data.date.date);
										const dateAsString = date.format("YYYYMMDD");

										for (let source in data.conversions) {
											const resolvedSource = this.resolveSource(source);

											if (!inquiries[inquirySource.slug][dateAsString]) {
												inquiries[inquirySource.slug][dateAsString] = {};
											}

											if (!inquiries[inquirySource.slug][dateAsString][resolvedSource]) {
												inquiries[inquirySource.slug][dateAsString][resolvedSource] = {
													type: "conversion",
													date: data.date,
													sourceMedium:
													resolvedSource,
													value: 0,
												};
											}

											inquiries[inquirySource.slug][dateAsString][resolvedSource].value
												+= data["conversions"][source];
										}

										resolve();
									},
									() => {
										reject();
									}
								);
							})
						);
					}

					promises.push(
						new Promise((resolve, reject) => {
							inquirySource.service.countBySources(
								{
									filter_groups: [
										{
											filters: [
												{
													key: "date",
													value: start.format(
														"YYYY-MM-DD"
													),
													operator: "gt",
												},
												{
													key: "date",
													value: end.format(
														"YYYY-MM-DD"
													),
													operator: "lte",
												},
												{
													key: "customer",
													operator: "eq",
													value: this.customer.id,
												},
											],
										},
									],
								},
								({data}) => {
									if (!data.date) {
										return resolve();
									}

									const date = moment(data.date.date);
									const dateAsString = date.format("YYYYMMDD");

									for (let source in data.conversions) {

										const resolvedSource = this.resolveSource(source);

										if (!periodExceedsTo) {
											if (!inquiries[inquirySource.slug][dateAsString]) {
												inquiries[inquirySource.slug][dateAsString] = {};
											}

											if (!inquiries[inquirySource.slug][dateAsString][resolvedSource]) {
												inquiries[inquirySource.slug][dateAsString][resolvedSource] = {
													type: "conversion",
													date: data.date,
													sourceMedium:
													resolvedSource,
													value: 0,
												};
											}

											inquiries[inquirySource.slug][dateAsString][resolvedSource].value
												+= data["conversions"][source];
										}

										if (!graphInquiries[inquirySource.slug][dateAsString]) {
											graphInquiries[inquirySource.slug][dateAsString] = {};
										}

										if (!graphInquiries[inquirySource.slug][dateAsString][resolvedSource]) {
											graphInquiries[inquirySource.slug][dateAsString][resolvedSource] = {
												type: "conversion",
												date: data.date,
												sourceMedium: resolvedSource,
												value: 0,
											};
										}

										graphInquiries[inquirySource.slug][dateAsString][resolvedSource].value
											+= data["conversions"][source];
									}

									resolve();
								},
								() => {
									reject();
								}
							);
						})
					);
				}
			});

			Promise.all(promises).then(() => {
				Object.values(sources).forEach((source) => {
					this.$set(
						this.conversions[source.slug],
						"conversions",
						Object.values(inquiries[source.slug]).reduce(
							(inquiries, entry) => {
								return inquiries.concat(Object.values(entry));
							},
							[]
						)
					);

					this.$set(
						this.conversions[source.slug],
						"graphConversions",
						Object.values(graphInquiries[source.slug]).reduce(
							(inquiries, entry) => {
								return inquiries.concat(Object.values(entry));
							},
							[]
						)
					);

					this.$set(this.conversions[source.slug], "hasLoaded", true);
				});
			});
		},

		getRouteQuery(source) {
			if (!this.$route.query.source) {
				return true;
			}

			const sources = this.$route.query.source.split(",");

			return sources.includes(source);
		},

		initiateFilter() {
			const sources = {
				adwords: {
					label: $t("trafficSources.adwords"),
					slug: "adwords",
					color: $org("colors.trafficSources.adwords"),
					selected: this.getRouteQuery("adwords"),
				},
				facebook: {
					label: $t("trafficSources.facebook"),
					slug: "facebook",
					color: $org("colors.trafficSources.facebook"),
					selected: this.getRouteQuery("facebook"),
				},
				organic: {
					label: $t("trafficSources.organic"),
					slug: "organic",
					color: $org("colors.trafficSources.organic"),
					selected: this.getRouteQuery("organic"),
				},
				direct: {
					label: $t("trafficSources.direct"),
					slug: "direct",
					color: $org("colors.trafficSources.direct"),
					selected: this.getRouteQuery("direct"),
				},
				referral: {
					label: $t("trafficSources.referral"),
					slug: "referral",
					color: $org("colors.trafficSources.referral"),
					selected: this.getRouteQuery("referral"),
				},
			};

			eventHub.$emit("topbar.filterGroups.push", {
				title: $t("conversions.sources"),
				slug: "sources",
				multiSelect: true,
				metrics: Object.values(sources).map((network) => {
					return {
						label: network.label,
						slug: network.slug,
						color: network.color,
						selected: network.selected,
					};
				}),
			});

			eventHub.$on(
				"topbar.filterGroups.sources.metricsChanged",
				(payload) => {
					this.visible = Object.keys(payload.metrics)
						.map((metricKey) => {
							if (!payload.metrics[metricKey]) {
								return;
							}

							return metricKey;
						})
						.filter((metricKey) => metricKey !== undefined);
				}
			);
		},

		resolveSource(sourceMedium) {
			if (sourceMedium === "seo") {
				sourceMedium = "organic";
			}

			let source = null;

			this.sources.forEach((trafficSource) => {
				if (trafficSource.slug === sourceMedium) {
					source = trafficSource.slug;
				}
			});

			return source ? source : "referral";
		},

		missingRequiredIntegration(conversionGroup) {
			return (
				(conversionGroup.slug === "calls" &&
					!this.hasIntegration("Freespee")) ||
				(conversionGroup.slug === "emails" &&
					!this.hasIntegration("Formstack")) ||
				(conversionGroup.slug === "goals" &&
					conversionGroup.conversions.length === 0)
			);
		},

		sumConversionsForGroup(conversionGroup) {
			return conversionGroup.conversions.reduce(
				(accumulator, conversion) => {
					if (conversion.type === "conversionGroup") {
						return (
							accumulator +
							this.sumConversionsForGroup(conversion)
						);
					}

					return accumulator + conversion.value;
				},
				0
			);
		},
	},

	components: {
		CheckForMissingIntegrations,
		LandingPage,
		Integration,
		PunchCard,
		ConversionTypes,
		ConversionChart,
		Ecommerce,
		Gbp,
	},
};
</script>
