import ApiService from '@/services/_app/ApiService'

const namespace = 'analytics/ecommerce'

export default class EcommerceService {
  static transactions (from, to) {
    return ApiService.get(namespace + '/transactions/' + ApiService.formatToUrl(from, to))
  }

  static revenuePerTransaction (from, to) {
    return ApiService.get(namespace + '/revenue-per-transaction/' + ApiService.formatToUrl(from, to))
  }

  static totalRevenue (from, to) {
    return ApiService.get(namespace + '/total-revenue/' + ApiService.formatToUrl(from, to))
  }
}
