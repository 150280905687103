<template>
	<card :icon="['fab', 'google']"
		  v-if="hasLoaded"
		  :headline="$t('conversions.gmb.gmb')"
		  :description="$t('conversions.notInGraph')">
		<div class="table-wrapper table-selectable table-column-even">
			<div class="table-header">
				<div class="col-lg-10 col-md-12 table-column header-border table-header-title">
					<h6>{{ $t('conversions.gmb.metric') }}</h6>
				</div>

				<div class="col-lg-2 hidden-md hidden-sm hidden-xs">
					<div class="row">
						<div class="col-lg-12 table-column insight-explainer-icon">
							<i>
								<fa icon="asterisk"/>
							</i>
							<small class="table-header__text">{{ $t('conversions.gmb.total') }}</small>
						</div>
					</div>
				</div>
			</div>

			<div class="table-body">
				<div class="col-lg-12 table-row" v-for="(value, metric) in values" :key="metric">
					<div class="row">
						<div class="col-lg-10 table-column campaign-name header-border hidden visible-lg">
							{{ $t(`conversions.gmb.${metric}`) }}
						</div>


						<div class="col-lg-2 table-column insight-value text-center hidden visible-lg">
							<small>{{ value }}</small>
						</div>


						<div class="col-sm-12 col-xs-12 col-md-12 hidden-lg">
							<div class="row mobile-container">
								<div class="col-md-4 col-sm-4 col-xs-6 table-column insight-explainer-icon">
									<small class="table-header__text">
										{{ $t(`conversions.gmb.${metric}`) }}
									</small>
								</div>

								<div class="col-md-8 col-sm-8 col-xs-6 table-column insight-value text-center">
									{{ value }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</card>
</template>

<style lang="scss" scoped>
@import '~@/assets/scss/_table.scss';
</style>

<script>
import * as LocationService from '@/services/google/LocationService'
import {getTranslatedErrorMessage} from "@/services/response/ResponseService";
import hasIntegration from '@/mixins/integrations/hasIntegration'
import datepickerMixin from '@/app/datepicker/datepicker.mixin'
import moment from 'moment'

export default {
	mixins: [hasIntegration, datepickerMixin],

	data() {
		return {
			values: {}
		}
	},

	mounted() {
		this.loadResults()
	},

	computed: {
		hasLoaded() {
			return Object.values(this.values).length > 0
		},
		getLocationName() {
			return (this.hiIntegrations.find(integration => integration.service.name === 'Google Business Profile') || {}).data
		},
	},

	methods: {
		loadResults() {
			if (this.getLocationName === undefined) {
				return
			}

			this.values = {};
			const end = moment(this.to);

			if (end.format("YYYY/MM/DD") === moment().format("YYYY/MM/DD")) {
				end.subtract(1, "day");
			}

			const startTime = new Date(this.from.toISOString());
			const endTime = end._d;
			const dailyRange = `&dailyRange__start_date__year=${startTime.getFullYear()}
								&dailyRange__start_date__month=${startTime.getMonth() + 1}
								&dailyRange__start_date__day=${startTime.getDate()}
								&dailyRange__end_date__year=${endTime.getFullYear()}
								&dailyRange__end_date__month=${endTime.getMonth() + 1}
								&dailyRange__end_date__day=${endTime.getDate()}
								`;

			const dailyMetrics = {
				WEBSITE_CLICKS: 'websiteClicks',
				CALL_CLICKS: 'phoneClicks',
				BUSINESS_IMPRESSIONS_DESKTOP_MAPS: 'viewsInMaps',
				BUSINESS_IMPRESSIONS_MOBILE_MAPS: 'viewsInMaps',
				BUSINESS_IMPRESSIONS_DESKTOP_SEARCH: 'viewsInSearch',
				BUSINESS_IMPRESSIONS_MOBILE_SEARCH: 'viewsInSearch',
				BUSINESS_DIRECTION_REQUESTS: 'drivingDirections',
			};

			const values = {
				viewsInSearch: 0,
				websiteClicks: 0,
				phoneClicks: 0,
				viewsInMaps: 0,
				drivingDirections: 0,
			};

			for (const metric in dailyMetrics) {
				const data = {
					locationName: this.getLocationName,
					query: `?dailyMetric=${metric}${dailyRange}`,
				};

				LocationService.insights(data, response => {
					if (response.data?.success === false) {
						this.$swal({
							type: 'error',
							text: getTranslatedErrorMessage(response.data?.errorCode)
						});
					} else {
						response.data.timeSeries.datedValues.forEach((item) => {
							if (item.value) {
								values[dailyMetrics[metric]] += parseInt(item.value);
							}
						});
					}
				});
			}

			this.values = values;
		}
	}
}
</script>
