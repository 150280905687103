const BaseService = require('@/services/BaseService');
const namespace = 'google-business-profile';

const location = (data, cb, errorCb) => {
	BaseService.get(`${namespace}/mybusinessbusinessinformation/v1/${data.locationName}?readMask=${data.readMask}`, {}, cb, errorCb)
}

const insights = (data, cb, errorCb) => {
	BaseService.get(`${namespace}/businessprofileperformance/v1/${data.locationName}:getDailyMetricsTimeSeries${data.query}`, {location: data.locationName}, cb, errorCb)
}

const media = (data, cb, errorCb) => {
	BaseService.get(`${namespace}/mybusiness/v4/${data.locationName}/media`, {}, cb, errorCb)
}

const reviews = (data, cb, errorCb) => {
	BaseService.get(`${namespace}/mybusiness/v4/${data.locationName}/reviews`, {}, cb, errorCb)
}

const updateHours = (payload, cb) => {
	const data = payload.data
	BaseService.patch(`${namespace}/mybusinessbusinessinformation/v1/${payload.locationName}?updateMask=${payload.updateMask}`, data, cb)
}

export {
	location,
	media,
	reviews,
	insights,
	updateHours
}
