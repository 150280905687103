<template>
    <div class="inquiry-punch-card">
        <loader v-if="!hasLoaded"></loader>

        <div>
            <div class="chart"></div>
        </div>

        <div class="labels center-block" v-if="hasLoaded">
            <p>{{$t('inquiries.reporting.punchCard.answeredCalls')}}</p>
            <div class="gradient"></div>
            <p>{{$t('inquiries.reporting.punchCard.unansweredCalls')}}</p>
        </div>

        <div class="clearfix"></div>
    </div>
</template>

<style lang="scss" scoped>
    .inquiry-punch-card{
        min-height: 400px;
    }

    .chart {
        position: relative;
        width: 100%;
    }

    .loader {
        position: absolute;
        top: 40%;
        width: 100%;
    }

    .labels {
        width: 355px;
        margin-top: 15px;
        margin-bottom: 45px;

        p, div {
            float: left;
            font-size: 12px;
            margin: 0 5px;
            font-weight: 400;
        }

        .gradient {
            width: 120px;
            height: 16px;
            border-radius: 4px;

            background-image: -ms-linear-gradient(right, #D63C3C 0%, #5AA3ED 100%);
            background-image: -moz-linear-gradient(right, #D63C3C 0%, #5AA3ED 100%);
            background-image: -o-linear-gradient(right, #D63C3C 0%, #5AA3ED 100%);
            background-image: -webkit-gradient(linear, right top, left top, color-stop(0, #D63C3C), color-stop(100, #5AA3ED));
            background-image: -webkit-linear-gradient(right, #D63C3C 0%, #5AA3ED 100%);
            background-image: linear-gradient(to left, #D63C3C 0%, #5AA3ED 100%);
        }
    }
</style>

<script>
  import Loader from '@/app/shared/components/Loader'
  import Range from '@/app/layout/components/Range'

  const HighCharts = require('highcharts')
  const moment = require('moment')

  const InquiryService = require('@/services/inquiries/InquiryService')

  export default {
    data() {
      return {
        chart: null,
        hasLoaded: false,
        inquiries: [],
        series: [],
      }
    },

    props: {
      from: Object,
      to: Object,
    },

    watch: {
      from() {
        this.reload();
      },

      to() {
        this.reload();
      }
    },

    mounted() {
      this.load();
    },

    methods: {
      reload() {
        if (!this.hasLoaded) {
          return;
        }

        this.hasLoaded = false;
        this.series = [];
        // this.labels = [];

        if (this.chart) {
          this.chart.destroy();
        }

        setTimeout(this.load, 100);
      },

      load() {
        const to = moment(this.to);
        to.add(1, 'day');

        const data = {
          filter_groups: [
            {
              filters: [
                {
                  key: 'date',
                  value: this.from.format('YYYY-MM-DD'),
                  operator: 'gt',
                },
                {
                  key: 'date',
                  value: to.format('YYYY-MM-DD'),
                  operator: 'lt',
                }
              ]
            }
          ]
        }

        InquiryService.findAll(data, (response) => {
          const body = response.data

          this.inquiries = body.rows

          this.createSeries('Opkald', 'call');

          this.render()

          this.hasLoaded = true
        })
      },

      createSeries(serieName, type) {
        const serie = {
          name: serieName,
          data: [],
          marker: {
            symbol: 'circle'
          }
        }
        let counter = []
        let max = 0
        const size = 15;
        const minimumSize = 3

        // Filter inquires by type
        const inquiries = this.inquiries.filter(function (inquiry) {
          return (inquiry.discr == type)
        })

        // Initialize 2-dimentional array for counting by week day and hour
        for (let i = 0; i < 7; i++) {
          counter[i] = []
        }

        for (let i = 0; i < 7; i++) {
          for (let j = 0; j < 24; j++) {
            counter[i][j] = {count: 0, unanswered: 0}
          }
        }

        // Count calls in days and hours
        inquiries.forEach((inquiry) => {
          const date = moment(inquiry.date.date)
          const weekDay = date.format('e')
          const hour = (date.format('k') == 24) ? 0 : date.format('k');

          counter[weekDay][hour].count++

          if (counter[weekDay][hour].count > max) {
            max = counter[weekDay][hour].count
          }

          if (!inquiry.answered) {
            counter[weekDay][hour].unanswered++;
          }
        })

        // Create data points
        for (let i = 0; i < 7; i++) {
          for (let j = 0; j < 24; j++) {
            let count = counter[i][j].count
            let unanswered = counter[i][j].unanswered
            let unansweredPercentage = (count > 0 ) ? unanswered / count : 0

            // Grouping night hours
            if (j >= 0 && j < 6) {
              continue;
            }

            if (j == 6) {
              count = 0;
              unanswered = 0;

              for (let k = 0; k < 7; k++) {
                count += counter[i][k].count
                unanswered += counter[i][k].unanswered
              }

              unansweredPercentage = (count > 0 ) ? unanswered / count : 0
            }

            // Grouping evening hours
            if (j > 18 && j < 24) {
              continue;
            }

            if (j == 18) {
              count = 0;
              unanswered = 0;

              for (let k = 18; k < 24; k++) {
                count += counter[i][k].count
                unanswered += counter[i][k].unanswered
              }

              unansweredPercentage = (count > 0 ) ? unanswered / count : 0
            }

            // Create point
            const point = {
              y: i,
              x: j,
              marker: {radius: count / max * size },
              count: count,
              unanswered: unanswered,
            }

            // Set color
            if (point.count >= 1 && point.marker.radius < minimumSize) {
              point.marker.radius = minimumSize
            }

            if (unansweredPercentage > 0.02) {
                point.marker.fillColor = '#748dc7'
            }

            if (unansweredPercentage > 0.06) {
              point.marker.fillColor = '#a7637f'
            }

            if (unansweredPercentage > 0.15) {
              point.marker.fillColor = '#c34c57'
            }

            if (unansweredPercentage > 0.25) {
              point.marker.fillColor = '#ce4247'
            }

            serie.data.push(point);
          }
        }

        // Add serie to the components data object
        this.series.push(serie)
      },

      render() {
        const chartElement = this.$el.querySelector('.chart');

        this.chart = new HighCharts.Chart(chartElement, {

          chart: {
            defaultSeriesType: 'scatter',
            height: 400
          },

          title: {
            text: null
          },

          legend: {
            enabled: false
          },

          tooltip: {
            formatter: function() {
              return '<strong>' + (this.point.count - this.point.unanswered) + '</strong> ' + $t('inquiries.reporting.punchCard.answeredCalls') + '<br> <strong>' + this.point.unanswered + '</strong> ' + $t('inquiries.reporting.punchCard.unansweredCalls') + ' <br> ';
            }
          },

          xAxis: {
            categories: ['', '', '', '', '', '', '00:00 -<br>06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00',
              '13:00', '14:00', '15:00', '16:00', '17:00', '18:00 -<br> 24:00', '', '', '', '', '', '']
          },

          yAxis: {
            title: {
              text: null
            },

            categories: [
                $t('weekdays.monday'),
                $t('weekdays.tuesday'),
                $t('weekdays.wednesday'),
                $t('weekdays.thursday'),
                $t('weekdays.friday'),
                $t('weekdays.saturday'),
                $t('weekdays.sunday')
            ],
          },

          colors: ['#7cb5ec', '#e36760', '#00C69E', '#FE9D45', '#8085e9',
            '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1'],

          series: this.series,

          credits: false,
        });
      }
    },
    components: {
      Loader,
      Range
    }
  }
</script>
