<template>
    <card icon="shopping-basket" v-if="hasLoaded"
          :headline="$t('ecommerce.ecommerce')"
          :description="$t('conversions.notInGraph')">
        <div class="table-wrapper table-selectable">

            <div class="table-header clearfix">
                <div class="col-lg-5 col-md-12 table-column header-border table-header-title">
                    <h6>{{$t('ecommerce.source')}}</h6>
                </div>

                <div class="col-lg-7 hidden-md hidden-sm hidden-xs">
                    <div class="row">
                        <div class="col-lg-4 table-column insight-explainer-icon ">
                            <i>
                                <fa icon="hashtag"/>
                            </i>
                            <small class="table-header__text">{{$t('ecommerce.transactions')}}</small>
                        </div>
                        <div class="col-lg-4 table-column insight-explainer-icon ">
                            <i>
                                <fa icon="shopping-basket"/>
                            </i>
                            <small class="table-header__text">{{$t('ecommerce.avgRevenue')}}</small>
                        </div>
                        <div class="col-lg-4 table-column insight-explainer-icon">
                            <i>
                                <fa icon="shopping-basket"/>
                            </i>
                            <small class="table-header__text">{{$t('ecommerce.totalRevenue')}}</small>
                        </div>
                    </div>
                </div>
            </div>

            <div class="table-body clearfix">
                <div class="col-lg-12 table-row" v-for="source in sources">
                    <div class="row">
                        <div class="col-lg-5 table-column campaign-name header-border">{{translate(source.slug)}}</div>

                        <!-- DESKTOP -->
                        <div class="col-lg-7 hidden-md hidden-sm hidden-xs">
                            <div class="row">
                                <div class="col-lg-4 table-column insight-value text-center">
                                    <small v-if="transactions[source.slug] !== undefined">{{transactions[source.slug] |
                                        fixedNumber | numberFormat}}</small>
                                    <small class="text-muted" v-else>-</small>
                                </div>
                                <div class="col-lg-4 table-column insight-value text-center">
                                    <small v-if="transactions[source.slug] !== undefined && transactions[source.slug] !== 0 && totalRevenue[source.slug] !== undefined">
                                        {{totalRevenue[source.slug] / transactions[source.slug] | fixedNumber |
                                        numberFormat}} {{ $t('conversions.currency') }}
                                    </small>

                                    <small class="text-muted" v-else>-</small>
                                </div>
                                <div class="col-lg-4 table-column insight-value text-center">
                                    <small v-if="totalRevenue[source.slug] !== undefined">{{totalRevenue[source.slug] |
                                        fixedNumber | numberFormat}} {{ $t('conversions.currency') }}</small>
                                    <small class="text-muted" v-else>-</small>
                                </div>
                            </div>
                        </div>

                        <!-- MOBILE -->
                        <div class="col-sm-12 col-xs-12 col-md-12 hidden-lg">
                            <div class="row mobile-container">
                                <div class="col-md-4 col-sm-4 col-xs-6 table-column insight-explainer-icon ">
                                    <i class="fa"></i>
                                    <small class="table-header__text">{{$t('ecommerce.transactions')}}</small>
                                </div>

                                <div class="col-md-8 col-sm-8 col-xs-6 table-column insight-value text-center">
                                    <small v-if="transactions[source.slug] !== undefined">{{transactions[source.slug] |
                                        fixedNumber | numberFormat}}</small>
                                    <small class="text-muted" v-else>-</small>
                                </div>

                                <div class="col-md-4 col-sm-4 col-xs-6 table-column insight-explainer-icon ">
                                    <i class="fa"></i>
                                    <small class="table-header__text">{{$t('ecommerce.avgRevenue')}}</small>
                                </div>
                                <div class="col-md-8 col-sm-8 col-xs-6 table-column insight-value text-center">
                                    <small v-if="transactions[source.slug] !== undefined && transactions[source.slug] !== 0 && totalRevenue[source.slug] !== undefined">
                                        {{totalRevenue[source.slug] / transactions[source.slug] | fixedNumber |
                                        numberFormat}} {{ $t('conversions.currency') }}
                                    </small>
                                    <small class="text-muted" v-else>-</small>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-6 table-column insight-explainer-icon ">
                                    <i class="fa"></i>
                                    <small class="table-header__text">{{$t('ecommerce.totalRevenue')}}</small>
                                </div>
                                <div class="col-md-8 col-sm-8 col-xs-6 table-column insight-value text-center">
                                    <small v-if="totalRevenue[source.slug] !== undefined">{{totalRevenue[source.slug] |
                                        fixedNumber | numberFormat}} {{ $t('conversions.currency') }}</small>
                                    <small class="text-muted" v-else>-</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="clearfix table-footer">
                <div class="col-lg-12 table-row">
                    <div class="row">
                        <div class="col-lg-5 table-column campaign-name header-border">
                            <strong>{{$t('ecommerce.total')}}</strong>
                        </div>

                        <!-- DESKTOP -->
                        <div class="col-lg-7 hidden-md hidden-sm hidden-xs">
                            <div class="row">
                                <div class="col-lg-4 table-column insight-value text-center">
                                    <small><strong>{{transactionsTotal}}</strong></small>
                                </div>
                                <div class="col-lg-4 table-column insight-value text-center">
                                    <small><strong>{{transactionsAverage | fixedNumber | numberFormat}}
										{{ $t('conversions.currency') }}</strong></small>
                                </div>
                                <div class="col-lg-4 table-column insight-value text-center">
                                    <small><strong>{{revenueTotal | fixedNumber | numberFormat}} {{ $t('conversions.currency') }}</strong></small>
                                </div>
                            </div>
                        </div>

                        <!-- MOBILE -->
                        <div class="col-sm-12 col-xs-12 col-md-12 hidden-lg">
                            <div class="row mobile-container">
                                <div class="col-md-4 col-sm-4 col-xs-6 table-column insight-explainer-icon ">
                                    <i class="fa"></i>
                                    <small class="table-header__text">{{$t('ecommerce.total')}}</small>
                                </div>

                                <div class="col-md-8 col-sm-8 col-xs-6 table-column insight-value text-center">
                                    <small><strong>{{transactionsTotal}}</strong></small>
                                </div>

                                <div class="col-md-4 col-sm-4 col-xs-6 table-column insight-explainer-icon ">
                                    <i class="fa"></i>
                                    <small class="table-header__text">{{$t('ecommerce.avgRevenue')}}</small>
                                </div>
                                <div class="col-md-8 col-sm-8 col-xs-6 table-column insight-value text-center">
                                    <small><strong>{{transactionsAverage | fixedNumber | numberFormat}}
										{{ $t('conversions.currency') }}</strong></small>
                                </div>
                                <div class="col-md-4 col-sm-4 col-xs-6 table-column insight-explainer-icon ">
                                    <i class="fa"></i>
                                    <small class="table-header__text">{{$t('ecommerce.totalRevenue')}}</small>
                                </div>
                                <div class="col-md-8 col-sm-8 col-xs-6 table-column insight-value text-center">
                                    <small><strong>{{revenueTotal | fixedNumber | numberFormat}} {{ $t('conversions.currency') }}</strong></small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </card>
</template>

<style lang="scss" scoped>
    @import '~@/assets/scss/_table.scss';
</style>

<script>
  import { mapGetters } from 'vuex'

  import EcommerceService from '@/services/_app/google-analytics/EcommerceService'
  import hasIntegration from '@/mixins/integrations/hasIntegration'
  import trafficSourcesMixin from '@/app/traffic-sources/traffic-sources.mixin'
  import datepickerMixin from '@/app/datepicker/datepicker.mixin'

  export default {
    mixins: [hasIntegration, trafficSourcesMixin, datepickerMixin],

    data () {
      return {
        transactions: {},
        totalRevenue: {}
      }
    },

    mounted () {
      this.load()
    },

    computed: {
      hasLoaded () {
        const transactions = Object.values(this.transactions)

        return transactions.length > 0
      },

      transactionsTotal () {
        return Object.values(this.transactions).reduce((acc, item) => acc + item, 0)
      },

      transactionsAverage () {
        if (!this.transactionsTotal || !this.revenueTotal) {
          return 0
        }

        return this.revenueTotal / this.transactionsTotal
      },

      revenueTotal () {
        return Object.values(this.totalRevenue).reduce((acc, item) => acc + item, 0)
      }
    },

    methods: {
      async load () {
        if (!this.hasIntegration('Google analytics')) {
          return
        }

        this.transactions = {}
        this.totalRevenue = {}

        const transactions = await EcommerceService.transactions(this.from, this.to)
        transactions.rows.forEach(row => {
          this.$set(this.transactions, row['sourceMedium'], row['transactions'])
        })

        const totalRevenue = await EcommerceService.totalRevenue(this.from, this.to)
        totalRevenue.rows.forEach(row => {
          this.$set(this.totalRevenue, row['sourceMedium'], row['transactionRevenue'])
        })
      }
    }
  }
</script>
