import ApiService from '@/services/_app/ApiService'

const namespace = 'analytics/goal'

export default class GoalService {
  static find (id) {
    return ApiService.get(namespace + '/' + id)
  }

  static insights (id, from, to) {
    return ApiService.get(namespace + '/' + id + '/insights/' + ApiService.formatToUrl(from, to))
  }

  static insightsGroupedByDate (id, from, to, dateGroup) {
    return ApiService.get(namespace + '/' + id + '/insights-date/' + ApiService.formatToUrl(from, to) + '/' + dateGroup)
  }

  static findAll (from, to) {
    return ApiService.get(namespace+ '/' + ApiService.formatToUrl(from, to))
  }
}
