<template>
	<div class="conversion-chart">
		<div ref="chart" class="chart"></div>
	</div>
</template>

<script>
import moment from "moment";
import HighCharts from "highcharts";
import trafficSourcesMixin from "@/app/traffic-sources/traffic-sources.mixin";
import datepickerMixin from "@/app/datepicker/datepicker.mixin";

export default {
	mixins: [trafficSourcesMixin, datepickerMixin],
	props: {
		visible: {
			type: Array,
			required: true,
			default: () => {
				return [];
			},
		},

		conversions: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			chart: null,
			labels: [],
			series: [],
			lineWidth: 1,
			marker: {
				symbol: "circle",
				radius: 3,
				lineWidth: 0,
				lineColor: "#fff",
			},
			cancel: null,
		};
	},
	watch: {
		conversions: "load",
	},

	mounted() {
		const visible = this.visible;
		this.internalVisible =
			visible.length > 0
				? visible
				: this.sources.map((source) => source.slug);
		this.load();
	},

	methods: {
		flattenConversionGroup(conversionGroup) {
			const clonedObject = JSON.parse(JSON.stringify(conversionGroup));

			let conversions = [];

			clonedObject.conversions.forEach((conversion) => {
				if (conversion.type === "conversionGroup") {
					const subConversions =
						this.flattenConversionGroup(conversion);

					subConversions.forEach((subConversion) => {
						conversions.push(subConversion);
					});
					return;
				}

				conversions.push(conversion);
			});

			return conversions;
		},

		load() {
			const series = [];
			const labels = [];

			Object.keys(this.conversions).forEach((conversionSource) => {
				const conversionGroup = this.conversions[conversionSource];
				const flattenConversionGroup = this.flattenConversionGroup(conversionGroup);
				const sortedConversionGroup = flattenConversionGroup.sort((a,b) => (a.date.date > b.date.date) ? 1 : ((b.date.date > a.date.date) ? -1 : 0));
				const groupedConversions = [];

				let date = '';
				let conversionValue = 0;

				sortedConversionGroup.forEach((conversion, index) => {
					if (index === 0) {
						date = conversion.date.date;
					}

					if (date === conversion.date.date) {
						conversionValue += conversion.value;
					} else {

						labels.push(this.getLabel(moment(date)));
						groupedConversions.push(conversionValue);

						date = conversion.date.date;
						conversionValue = 0;
					}
				});

				series.push({
					name: conversionSource,
					data: groupedConversions,
					marker: this.marker,
					color: conversionGroup.serieColor,
					zoneAxis: "x",
				});
			});

			this.series = series;
			this.labels = labels;
			this.render();
		},

		render() {
			if (!this.$el) {
				return;
			}

			const el = this.$el.querySelector(".chart");
			let series = this.series;

			if (this.chart) {
				this.chart.series.forEach((serie) => {
					const matchingSeries = this.series.filter(
						(matchingSerie) => matchingSerie.name === serie.name
					);

					if (!matchingSeries.length) {
						return;
					}

					serie.update({
						data: matchingSeries[0].data,
					});
				});

				this.chart.xAxis.categories = this.labels;
			}

			const that = this;

			this.chart = new HighCharts.Chart(el, {
				credits: false,
				chart: {
					type: "area",
					stacking: "normal",
					zoomType: "x",
					height: 250,
				},
				title: {
					text: null,
				},
				legend: {
					enabled: false,
				},
				tooltip: {
					shared: true,
					formatter: function () {
						let total = 0;
						let string = "<strong>" + this.x + "</strong><br />";

						this.points.forEach(function (point) {
							string +=
								that.translate(
									point.series.name,
									"conversions"
								) + ":";
							string += point.y;
							string += "<br />";
							total += point.y;
						});

						return string;
					},
				},
				plotOptions: {
					area: {
						stacking: "normal",
					},
					series: {
						marker: {
							enabled: true,
						},
					},
				},
				xAxis: {
					categories: this.labels,
				},
				yAxis: {
					title: {
						text: $t("conversions.conversions"),
					},
				},
				series: series,
			});
		},
	},
};
</script>
